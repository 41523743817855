import React, { ReactElement, useContext, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { toast } from "react-toast";
import { Button, PasswordInput } from "luxd";
import { FaDiscord } from "react-icons/fa";
import { Error, Token } from "../../types";
import { FormStatus } from "./forms";
import useAppwrite from "../../hooks/useAppwrite";
import { SocialLinks } from "../SocialLinks";
import LoginContainer from "./loginFormContainer";
import { MixpanelContext } from "../../metrics";
import { useForm } from "react-hook-form";
import { ApiCall } from "../../appwrite/appwrite";
import { gotoLogin, joinDiscord } from "../../utils/navigation";

type PasswordRecoveryFormInputs = {
    password: string;
    confirmPassword: string;
};

const PasswordRecoveryForm = (): ReactElement => {
    const [userId] = useQueryParam("userId", StringParam);
    const [secret] = useQueryParam("secret", StringParam);

    const [error, setError] = useState<Error>();
    const [status, setStatus] = useState<FormStatus>();

    const appwrite = useAppwrite();

    if (!userId || !secret) {
        return <p>error</p>;
    }

    const onSubmit = (data: PasswordRecoveryFormInputs) => {
        setStatus("loading");
        ApiCall<Token>(
            appwrite.account.updateRecovery(
                userId,
                secret,
                data.password,
                data.confirmPassword
            ),
            tokenResp => {
                setStatus("success");
                mixpanel.track("Complete password recovery");
                toast.success("Password updated successfully");
                gotoLogin(true);
            },
            err => {
                setStatus(undefined);
                toast.error(err.message);
            }
        );
    };

    const mixpanel = useContext(MixpanelContext);

    const { register, handleSubmit, errors, getValues } = useForm<
        PasswordRecoveryFormInputs
    >();

    return (
        <LoginContainer>
            <div className="flex flex-col justify-center md:space-y-8 space-y-4">
                <div className="flex flex-col space-y-4 justify-between sm:mx-8 mt-8 text-offWhite items-center">
                    <h1 className="text-2xl font-display text-center">
                        Password recovery
                    </h1>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-2 sm:mt-6 flex flex-col space-y-8 mx-2 md:mx-4 xl:mx-8"
                >
                    <div className="flex flex-col space-y-2">
                        <PasswordInput
                            name="password"
                            label="New password"
                            id="password"
                            ref={register({
                                required: true,
                                minLength: {
                                    value: 8,
                                    message:
                                        "Password must be at least 8 characters",
                                },
                            })}
                            error={errors.password?.message}
                        />
                        <PasswordInput
                            name="confirmPassword"
                            label="Confirm new password"
                            id="confirmPassword"
                            ref={register({
                                required: true,
                                validate: value =>
                                    value === getValues().password ||
                                    "Does not match password",
                            })}
                            error={errors.confirmPassword?.message}
                        />
                    </div>

                    <Button
                        loading={status === "loading"}
                        color="primary"
                        size="normal"
                    >
                        Submit
                    </Button>
                </form>
                <div className="flex flex-col space-y-4 items-center text-offWhite">
                    <div className="flex flex-row sm:space-x-4 space-x-2 justify-center pt-4 items-center">
                        <div className="flex flex-row items-center">
                            <p className="whitespace-nowrap">
                                Account trouble?
                            </p>
                        </div>
                        <Button
                            icon={<FaDiscord />}
                            size="small"
                            onClick={() => joinDiscord()}
                        >
                            Join our Discord
                        </Button>
                    </div>
                </div>
                <SocialLinks />
            </div>
        </LoginContainer>
    );
};

export default PasswordRecoveryForm;
