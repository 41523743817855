import React, { ReactElement } from 'react';
import PasswordRecoveryForm from '../components/forms/PasswordRecoveryForm';
import SEO from '../components/seo';

const Recover = ({location}: any): ReactElement => {

    return (
        <>
            <SEO title='Recover password' description='Recover your password.' />
            <PasswordRecoveryForm />
        </>
    );
};

export default Recover;
